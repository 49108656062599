import configJsonQA from "./auth_config.qa.json";
import configJsonProd from "./auth_config.production.json";

export function getConfig() {
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.

let returnValue = {};

if(process.env.REACT_APP_ENVIRONMENT == "production"){
  returnValue.audience =
  configJsonProd.audience ? configJsonProd.audience
    : null;

    returnValue.domain = configJsonProd.domain;
    returnValue.clientId = configJsonProd.clientId;
}else{
  returnValue.audience = configJsonQA.audience
  configJsonQA.audience ? configJsonQA.audience
      : null;
  returnValue.domain = configJsonQA.domain;
  returnValue.clientId = configJsonQA.clientId;
}

 

  return returnValue;
}
