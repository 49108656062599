const campaignApi = {
  createAutoResponse: async (token, campaign_id, autoresponse) => {
    var result = await fetch(
      process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN +
        "campaigns/autoresponse/create",
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          campaign_id: campaign_id,
          autoresponses: autoresponse,
        }),
      }
    );
    const resultJson = await result.json();
  },

  createCampaign: async (
    token,
    campaignName,
    campaignMessage,
    selectedContactList,
    messageType,
    filename
  ) => {
    console.log(filename);
    var result = await fetch(
      process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN + "campaigns/create",
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          campaign_name: campaignName,
          message: campaignMessage,
          contact_list_id: selectedContactList.contact_list_id,
          message_type: messageType,
          filename: filename,
        }),
      }
    );
    const resultJson = await result.json();
    return resultJson.results[0].create_campaign;
  },

  startCampaign: async (token, campaignId) => {
    await fetch(
      process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN + "messages/send",
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          campaign_id: campaignId,
        }),
      }
    );
  },

  getCampaignList: async (token) => {
    var result = await fetch(
      process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN + "campaigns/list",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    const jsonHold = await result.json();
    var hold = jsonHold.results;
    hold.sort(function (a, b) {
      return b.id - a.id;
    });
    return hold;
  },
};

export default campaignApi;
