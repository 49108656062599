const redirectApi = {
    generateContactUrls: async (token, campaign_id) => {
        var result = await fetch(
            process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN +
            "authredirect/create",
            {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify({
                    campaign_id: campaign_id
                }),
            }
        );
        const resultJson = await result.json();
    },
    storeRedirectUrl: async (token, campaign_id, redirect_url) => {
        var result = await fetch(
            process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN +
            "authredirect/store",
            {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify({
                    campaign_id: campaign_id,
                    redirect_url: redirect_url
                }),
            }
        );
        const resultJson = await result.json();
    }
};

export default redirectApi;
