import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import CreateCampaign from "./CreateCampaign";
import { useAuth0 } from "@auth0/auth0-react";
import { DataGrid } from "@mui/x-data-grid";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CampaignStats from "./CampaignStats";
import CircularProgress from "@mui/material/CircularProgress";
import campaignApi from "../../services/campaignApi"


function ManageCampaigns() {
  const [campaignLists, setCampaignLists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [createCampaign, setCreateCampaign] = useState(false);
  const [clientList, setClientList] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const [openStatDialog, setOpenStatDialog] = useState(false);
  const [dialogCampaignId, setDialogCampaignId] = useState(0);
  const [dialogCampaignName, setDialogCampaignName] = useState("");

  useEffect(async () => {
    getCampaignList(); 
    const token = await getAccessTokenSilently();   
    fetch(process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN + "clients/list", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setClientList(result.results);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {}
      );
  }, []);

  const getCampaignList = async() =>{
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    var result = await campaignApi.getCampaignList(token);
    setCampaignLists(result);
    setIsLoading(false);
  }

  const handleCreateCampaignButton = () => {
    setCreateCampaign(true);
  };

  const handleBackButtonClickCampaignCreate = () => {
    setCreateCampaign(false);
    getCampaignList();
  };

  const handleOnCloseStats = () => {
    setOpenStatDialog(false);
    setDialogCampaignId(0);
  };

  const handleOpenCampaignStats = (name, id, event) => {
    setDialogCampaignId(id);
    setDialogCampaignName(name);
    setOpenStatDialog(true);
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "message_type",
      label: "Message Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "contact_list_name",
      label: "Contact List",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "send_type",
      label: "Send Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "create_date",
      label: "Create Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "id",
      label: "Stats",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              onClick={handleOpenCampaignStats.bind(
                this,
                tableMeta.rowData[0],
                value
              )}
            >
              View
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    print: false,
    download: false,
    selectableRows: "none",
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CircularProgress color="inherit" />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
  };

  if (createCampaign) {
    return (
      <div>
        <CreateCampaign
          clientList={clientList}
          onBack={handleBackButtonClickCampaignCreate}
        ></CreateCampaign>
      </div>
    );
  } else {
    return (
      <div>
        <Grid
          container
          spacing={2}
          sx={{ marginBottom: "20px" }}
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <Button
              sx={{ float: "right" }}
              onClick={handleCreateCampaignButton}
              variant="contained"
            >
              Create Campaign
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MUIDataTable
              title={"Campaigns"}
              data={campaignLists}
              columns={columns}
              options={options}
            />
          </Grid>
        </Grid>
        <CampaignStats
          open={openStatDialog}
          onClose={handleOnCloseStats}
          campaign_id={dialogCampaignId}
          campaign_name={dialogCampaignName}
        />
      </div>
    );
  }
}

export default ManageCampaigns;
