import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useAuth0 } from "@auth0/auth0-react";


function AutoResponses(props) {
    const { autoResponses, setAutoResponses, isLoading } = props;
    const { getAccessTokenSilently } = useAuth0();


    const calcSms = (count) => {
        if (count === 0) {
            return "0/0";
        }
        let hold =
            count +
            "/" +
            (Math.floor(count / 160) === 0 ? 1 : Math.ceil(count / 160));
        return hold;
    };

    const createKeywordAutoResponse = () => {
        var addKeyword = [...autoResponses];
        addKeyword.push({
            id: autoResponses.length,
            title: "",
            keyword: "",
            body: "",
            matchRule: "contains",
            tier: 1
        });
        setAutoResponses(addKeyword);
    };

    const removeKeyword = (index) => {
        var removeKeyword = [...autoResponses];
        removeKeyword.splice(index, 1);
        setAutoResponses(removeKeyword);
    };

    const handleKeywordTitleChange = (script) => (event) => {
        var titleChange = [...autoResponses];
        var location = titleChange.findIndex((item) => item.id === script.id);
        titleChange[location].title = event.target.value;
        setAutoResponses(titleChange);
    };

    const handleKeywordChange = (script) => (event) => {
        var keywordChange = [...autoResponses];
        var location = keywordChange.findIndex((item) => item.id === script.id);
        keywordChange[location].keyword = event.target.value;
        setAutoResponses(keywordChange);
    };

    const handleKeywordBodyChange = (script) => (event) => {
        var bodyChange = [...autoResponses];
        var location = bodyChange.findIndex((item) => item.id === script.id);
        bodyChange[location].body = event.target.value;
        setAutoResponses(bodyChange);
    };

    const handleKeywordMatchRuleChange = (script) => (event) => {
        var ruleChange = [...autoResponses];
        var location = ruleChange.findIndex((item) => item.id === script.id);
        ruleChange[location].matchRule = event.target.value;
        setAutoResponses(ruleChange);
    };

    const handleKeywordtierChange = (script) => (event) => {
        var ruleChange = [...autoResponses];
        var location = ruleChange.findIndex((item) => item.id === script.id);
        ruleChange[location].tier = event.target.value;
        setAutoResponses(ruleChange);
    };


    const ListScripts = autoResponses.map((script, index) => {
        return (
            <Grid item xs={4} key={script.id}>
                <TextField
                    id="outlined-basic"
                    label="Title"
                    variant="outlined"
                    fullWidth={true}
                    onChange={handleKeywordTitleChange(script)}
                    disabled={isLoading}
                />
                <TextField
                    id="outlined-basic"
                    label="Keyword"
                    variant="outlined"
                    fullWidth={true}
                    onChange={handleKeywordChange(script)}
                    margin="normal"
                    disabled={isLoading}
                />
                <FormControl variant="outlined" >
                    <InputLabel id="demo-simple-select-outlined-label">Match Rule</InputLabel>
                    <Select
                        id="demo-simple-select-outlined"
                        value={script.matchRule}
                        onChange={handleKeywordMatchRuleChange(script)}
                        label="Match Rule"
                        disabled={isLoading}
                    >
                        <MenuItem value={'contains'}>Contains</MenuItem>
                        <MenuItem value={'starts'}>Starts</MenuItem>
                        <MenuItem value={'ends'}>Ends</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" style={{ paddingLeft: 10 }}>
                    <InputLabel id="demo-simple-select-outlined-label">Tier</InputLabel>
                    <Select
                        id="demo-simple-select-outlined"
                        value={script.tier}
                        onChange={handleKeywordtierChange(script)}
                        label="Message Tier"   
                        disabled={isLoading}                     
                    >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    id="filled-multiline-flexible-new-contacts"
                    label="Script Body"
                    multiline
                    onChange={handleKeywordBodyChange(script)}
                    margin="normal"
                    variant="outlined"
                    helperText={calcSms(script.body.length)}
                    fullWidth={true}
                    disabled={isLoading}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        removeKeyword(index);
                    }}
                    disabled={isLoading}
                >
                    Remove
                </Button>
            </Grid>
        );
    });

    return (
        <div>
            <Grid container>
                <Grid item xs={5}>
                    <h3>Keyword AutoResponses</h3>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={createKeywordAutoResponse}
                        disabled={isLoading}
                    >
                        Add
                    </Button>
                </Grid>
                <Grid item xs={8}></Grid>
            </Grid>
            {ListScripts.length > 0 && (
                <Grid container spacing={4}>
                    {ListScripts}
                </Grid>
            )}
        </div>
    );
}

export default AutoResponses