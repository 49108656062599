import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

export default function DeleteClientDialog(props) {
  const { onClose, deleteClient, open } = props;
  const { getAccessTokenSilently } = useAuth0();
  const [disableButton, setDisableButton] = useState(false);

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") {
      return;
    } else {
      onClose();
    }
  };

  const handleYesButton = async () => {
    setDisableButton(true);
    const token = await getAccessTokenSilently();
    const url = process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN;
    await fetch(url + "clients/delete", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        client_id: deleteClient,
      }),
    });

    handleClose();
    setDisableButton(false);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Delete Client</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete?</Typography>
        <Typography>
          It will remove all tied contacts lists to this client.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={disableButton}>
          Cancel
        </Button>
        <Button
          onClick={handleYesButton}
          disabled={disableButton}
          variant="contained"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteClientDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
