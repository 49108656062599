import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Button, DialogActions, DialogContent } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useAuth0 } from "@auth0/auth0-react";

export default function CreateContactsDialog(props) {
  const { onClose, selectedValue, open } = props;
  const [clientName, setclientName] = useState("");
  const [required, setIsRequired] = useState(false);
  const [clientPhone, setClientPhone] = useState("");
  const [clientPhoneError, setClientPhoneError] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [clientNameErrorText, setclientNameErrorText] = useState("");
  const [clientPhoneErrorText, setclientPhoneErrorText] = useState("");

  const CHARACTER_LIMIT = 300;

  const handleClose = (event, reason) => {
    setIsLoading(false);
    setClientPhone("");
    setClientPhoneError(false);
    setclientPhoneErrorText("");
    setclientNameErrorText("");
    setclientName("");
    if (reason && reason == "backdropClick") {
      return;
    } else {
      onClose(selectedValue);
    }
  };

  const handleClientChange = (event) => {
    setclientName(event.target.value);
  };

  const handleCreateButton = () => {
    const phoneRegex = /^(\+\d{1,2}\d{3}\d{3}\d{4})$/;
    if (clientName.length === 0) {
      setIsRequired(true);
      setclientNameErrorText("Required");
      return;
    } else if (clientName.length >= 300) {
      setIsRequired(true);
      setclientNameErrorText("Name must be less than 300 characters");
      return;
    } else {
      setIsRequired(false);
      setclientNameErrorText("");
    }
    if (clientPhone && !clientPhone.match(phoneRegex)) {
      setClientPhoneError(true);
      setclientPhoneErrorText("Incorrect Format '+15555555555'");
      return;
    } else {
      setClientPhoneError(false);
      setclientPhoneErrorText("");
    }
    create_client();
  };

  const handlePhoneChange = (event) => {
    setClientPhone(event.target.value);
  };

  async function create_client() {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    const result = await fetch(
      process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN + "clients/create",
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          client_name: clientName,
        }),
      }
    );
    if (clientPhone) {
      const resultJson = await result.json();
      await create_client_phone(resultJson.results[0].create_client);
    } else {
      handleClose();
    }
  }

  async function create_client_phone(client_id) {
    const token = await getAccessTokenSilently();
    await fetch(
      process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN +
        "clients/phonenumbers/create",
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          client_id: client_id,
          phone_number: clientPhone,
        }),
      }
    );

    handleClose();
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      {/* {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )} */}
      <DialogTitle>Create Client</DialogTitle>

      <DialogContent>
        <Grid container spacing={2} sx={{ paddingTop: "20px" }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="outlined-basic"
              label="Client Name"
              inputProps={{
                maxLength: CHARACTER_LIMIT,
              }}
              variant="outlined"
              value={clientName}
              onChange={handleClientChange}
              error={required}
              disabled={isLoading}
              helperText={clientNameErrorText}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder="+15555555555"
              id="outlined-basic"
              label="Phone"
              variant="outlined"
              value={clientPhone}
              onChange={handlePhoneChange}
              error={clientPhoneError}
              disabled={isLoading}
              helperText={clientPhoneErrorText}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          onClick={handleCreateButton}
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateContactsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
