import React, { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Button, DialogActions, DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useAuth0 } from "@auth0/auth0-react";
import reportsApi from "../../services/reportsApi";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CampaignStats(props) {
  const { onClose, campaign_id, campaign_name, open } = props;
  const [isLoadingStats, setIsLoadingStats] = useState(false);
  const [campaignStats, setCampaignStats] = useState({});
  const [showSnackBar, setShowSnackBar] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(async () => {
    if (campaign_id != 0) {
      await getCampaignStats();
    }
  }, [open]);

  const handleClose = (event, reason) => {
    setCampaignStats({});
    onClose();
  };

  async function getCampaignStats() {
    setIsLoadingStats(true);
    const token = await getAccessTokenSilently();
    try {
      const result = await fetch(
        process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN +
          "campaigns/stats/" +
          campaign_id,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      const resultJson = await result.json();
      setCampaignStats(resultJson.results[0]);
      setIsLoadingStats(false);
    } catch (error) {
      console.error(error);
      setIsLoadingStats(false);
    }
  }


  async function handleDownloadButtonClick() {
    const token = await getAccessTokenSilently();
    await setIsLoadingStats(true);
    reportsApi.getDeliveryReportSignedUrl(token, campaign_id)
    await setIsLoadingStats(false);
  };

  const handleOnDemandButtonClick = async () => {
    const token = await getAccessTokenSilently();
    await setIsLoadingStats(true);
    reportsApi.getOnDemandCampaignReport(token, campaign_id)
    setShowSnackBar(true);
    await setIsLoadingStats(false);
  };

  const handleSnackClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return;
    // }

    setShowSnackBar(false);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{campaign_name}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ paddingTop: "20px" }}>
          <Grid item xs={12}>
            Start Time: {campaignStats.start_time}
          </Grid>
          <Grid item xs={12}>
            End Time: {campaignStats.end_time}
          </Grid>
          <Grid item xs={12}>
            Total Sent: {campaignStats.total_count}
          </Grid>
          <Grid item xs={12}>
            Delivered: {campaignStats.delivered_count}
          </Grid>
          <Grid item xs={12}>
            Undelivered: {campaignStats.undelivered_count}
          </Grid>
          <Grid item xs={12}>
            Failed: {campaignStats.count_failed}
          </Grid>
          <Grid item xs={12}>
            Rejected: {campaignStats.rejected_count}
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={handleDownloadButtonClick} disabled={isLoadingStats}>
              Download Delivery Report
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={handleOnDemandButtonClick} disabled={isLoadingStats}>
              Request Campaign Report
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions></DialogActions>
      <Snackbar open={showSnackBar} autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
          Campaign Report Creating, it will be emailed to you!
        </Alert>
      </Snackbar>
    </Dialog>    
  );
}

export default CampaignStats;
