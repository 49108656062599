import React, { useState } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Button, DialogActions, DialogContent } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useAuth0 } from "@auth0/auth0-react";

export default function CreateClientPhoneNumberDialog(props) {
  const { onClose, client_id, open } = props;
  const [clientPhone, setClientPhone] = useState("");
  const [clientPhoneError, setClientPhoneError] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [clientPhoneErrorText, setclientPhoneErrorText] = useState("");

  const handleClose = (event, reason) => {
    setClientPhoneError(false);
    setclientPhoneErrorText("");
    setClientPhone("");
    setIsLoading(false)
    if (reason && reason == "backdropClick") {
      return;
    } else {
      onClose();
    }
  };

  const handleCreatePhoneButton = () => {
    const phoneRegex = /^(\+\d{1,2}\d{3}\d{3}\d{4})$/;
    if (!clientPhone || !clientPhone.match(phoneRegex)) {
      setClientPhoneError(true);
      setclientPhoneErrorText("Incorrect Format '+15555555555'");
      return;
    } else {
      setClientPhoneError(false);
      setclientPhoneErrorText("");
    }
    create_client_phone();
  };

  const handlePhoneChange = (event) => {
    setClientPhone(event.target.value);
  };

  async function create_client_phone() {
    setIsLoading(true)
    const token = await getAccessTokenSilently();
    const result = await fetch(
      process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN +
        "clients/phonenumbers/create",
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          client_id: client_id,
          phone_number: clientPhone,
        }),
      }
    );
    setIsLoading(false)
    handleClose();
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Create Client Phone Number</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ paddingTop: "20px" }}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="outlined-basic"
              label="Phone"
              variant="outlined"
              placeholder="+15555555555"
              value={clientPhone}
              onChange={handlePhoneChange}
              error={clientPhoneError}
              disabled={isLoading}
              helperText={clientPhoneErrorText}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>Cancel</Button>
        <Button onClick={handleCreatePhoneButton} disabled={isLoading} variant="contained">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateClientPhoneNumberDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
