import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import {Button, Typography} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useAuth0 } from "@auth0/auth0-react";
import AutoResponses from "./AutoResponses";
import campaignApi from "../../services/campaignApi";
import FileUpload from "react-material-file-upload";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import redirectApi from "../../services/redirectApi";

function CreateCampaign(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [selectedContactList, setSelectedContactList] = useState("");
  const [campaignMessage, setCampaignMessage] = useState("");
  const [clientConstactLists, setClientContactLists] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [requiredCampaignName, setRequiredCampaignName] = useState(false);
  const [requiredClient, setRequiredClient] = useState(false);
  const [requiredContactList, setRequiredContactList] = useState(false);
  const [requiredCampaignMessage, setRequiredCampaignMessage] = useState(false);
  const [keywordAutoResponses, setKeywordAutoResponses] = useState([]);
  const [files, setFiles] = useState([]);
  const [mms, setMMS] = useState(false);
  const [urlRedirect, setRedirect] = useState(false);
  const [urlValue, setUrlValue] = useState("")
  const [requiredUrlValue, setRequiredUrlValue] = useState(false)
  const { getAccessTokenSilently } = useAuth0();
  const { onBack, clientList } = props;

  const CHARACTER_LIMIT = 300;

  const getContactListsForClient = async (client) => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    fetch(
      process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN +
        "contacts/list/" +
        client.id,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setClientContactLists(result.results);
          setIsLoading(false);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoading(true);
        }
      );
  };

  const handleCreateCampaignButton = async () => {
    var needRequired = false;
    if (campaignName.length == 0) {
      setRequiredCampaignName(true);
      needRequired = true;
    } else {
      setRequiredCampaignName(false);
    }
    if (!selectedClient) {
      setRequiredClient(true);
      needRequired = true;
    } else {
      setRequiredClient(false);
    }
    if (!selectedContactList) {
      setRequiredContactList(true);
      needRequired = true;
    } else {
      setRequiredContactList(false);
    }
    if (campaignMessage.length === 0) {
      setRequiredCampaignMessage(true);
      needRequired = true;
    } else {
      setRequiredCampaignMessage(false);
    }
    if (urlRedirect && (urlValue.length === 0 || !validateURLHttp(urlValue))) {
      setRequiredUrlValue(true)
      needRequired = true;
    }else{
      setRequiredUrlValue(false)
    }
    if (!needRequired) {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      console.log(files);
      let campaignId = await campaignApi.createCampaign(
        token,
        campaignName,
        campaignMessage,
        selectedContactList,
        mms ? "mms" : "sms",
        files[0] ? files[0].name : ""
      );
      if (urlRedirect){
        await redirectApi.generateContactUrls(token, campaignId);
        await redirectApi.storeRedirectUrl(token, campaignId, urlValue)
      }
        await campaignApi.startCampaign(token, campaignId);
        await campaignApi.createAutoResponse(
          token,
          campaignId,
          keywordAutoResponses
        );
        setIsLoading(false);
        onBack();
       }
  };

  function validateURLHttp(str) {
    const urlRegex = /^(https:\/\/)/; // regular expression that matches 'https://'
    return urlRegex.test(str); // test if url starts with 'https://'
  }

  const getMmsUploadUrl = async (event) => {
    setFiles(event);
    if (event.length <= 0) {
      return;
    }

    const token = await getAccessTokenSilently();
    try {
      const body = { filename: event[0].name };
      const result = await fetch(
        process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN +
          "campaigns/mms/uploadurl",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(body),
          method: "post",
        }
      );

      const resultJson = await result.json();
      await uploadMedia(resultJson, event);
    } catch (error) {
      console.error(error);
    }
  };

  async function uploadMedia(signed_url, filesToUpload) {
    const formData = new FormData();
    Object.keys(signed_url.results.fields).forEach((key) => {
      formData.append(key, signed_url.results.fields[key]);
    });
    formData.append("file", filesToUpload[0]);
    const result = await fetch(signed_url.results.url, {
      method: "POST",
      body: formData,
    });
  }

  const handleCampaignChange = (event) => {
    setCampaignName(event.target.value);
  };

  const handleClientSelect = (event) => {
    setSelectedClient(event.target.value);
    getContactListsForClient(event.target.value);
  };

  const handleContactListSelect = (event) => {
    setSelectedContactList(event.target.value);
  };

  const handleCampaignMessageChange = (event) => {
    setCampaignMessage(event.target.value);
  };

  const handleMmsChange = (event) => {
    setMMS(event.target.checked);
  };

  const handleUrlRedirectChange = (event) => {
    setRedirect(event.target.checked);
  };

  const handleUrlValue = (event) => {
    setUrlValue(event.target.value);
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{ marginBottom: "20px" }}
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Grid item xs={2}>
          <Button
            sx={{ float: "left" }}
            variant="contained"
            onClick={onBack}
            startIcon={<ArrowBackIcon />}
            disabled={isLoading}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={8}></Grid>
        <Grid item xs={2}>
          <Button
            sx={{ float: "right" }}
            onClick={handleCreateCampaignButton}
            variant="contained"
            disabled={isLoading}
          >
            Create and Start Send
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            id="outlined-basic"
            label="Campaign Name"
            inputProps={{
              maxLength: CHARACTER_LIMIT,
            }}
            variant="outlined"
            value={campaignName}
            onChange={handleCampaignChange}
            helperText={`${campaignName.length}/${CHARACTER_LIMIT}`}
            error={requiredCampaignName}
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Clients</InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedClient}
              onChange={handleClientSelect}
              error={requiredClient}
              disabled={isLoading}
            >
              {clientList.map((row) => (
                <MenuItem key={row.id} value={row}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            style={{ marginTop: 15 }}
            required
            fullWidth
            id="outlined-multiline-static"
            label="Message to Send"
            disabled={isLoading}
            multiline
            rows={5}
            error={requiredCampaignMessage}
            value={campaignMessage}
            onChange={handleCampaignMessageChange}
            helperText={`${campaignMessage.length}/${
              Math.floor(campaignMessage.length / 160) === 0
                ? 1
                : Math.ceil(campaignMessage.length / 160)
            }`}
          />
        </Grid>
        <Grid item xs={6}>
          {clientConstactLists.length > 0 ? (
            <FormControl fullWidth style={{ marginTop: 15 }}>
              <InputLabel id="demo-simple-select-label">
                Contact List
              </InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedContactList}
                label="Contact List"
                onChange={handleContactListSelect}
                error={requiredContactList}
                disabled={isLoading}
              >
                {clientConstactLists.map((row) => (
                  <MenuItem key={row.id} value={row}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <div></div>
          )}
        </Grid>

          <Grid item xs={2}>
            <FormControlLabel
                control={<Switch checked={urlRedirect} onChange={handleUrlRedirectChange} />}
                label="Url Redirect"
                disabled={isLoading}
            />
          </Grid>
        { urlRedirect && (<Grid item xs={3}>
            <Typography >{"{{url_redirect}}"} variable replace in message</Typography>
          </Grid>)}
          { urlRedirect && (<Grid item xs={7}>
            <TextField
                fullWidth
                required
                id="outlined-basic"
                label="Url Redirect -- ex: https://example.com"
                inputProps={{
                  maxLength: CHARACTER_LIMIT,
                }}
                variant="outlined"
                value={urlValue}
                onChange={handleUrlValue}
                helperText={`${urlValue.length}/${CHARACTER_LIMIT}`}
                error={requiredUrlValue}
                disabled={isLoading}
            />
          </Grid>)}

        <Grid item xs={12}>
        <FormControlLabel
            control={<Switch checked={mms} onChange={handleMmsChange} />}
            label="MMS"
            disabled={isLoading}
          />
        </Grid>
        {mms && (
          <Grid item xs={12}>
            <FileUpload value={files} onChange={getMmsUploadUrl} />
          </Grid>
        )}
        <Grid item xs={12}>
          <AutoResponses
            isLoading={isLoading}
            autoResponses={keywordAutoResponses}
            setAutoResponses={setKeywordAutoResponses}
          ></AutoResponses>
        </Grid>
      </Grid>
    </div>
  );
}

export default CreateCampaign;
