import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CreateContactsDialog from "./CreateContactsDialog";
import { useAuth0 } from "@auth0/auth0-react";
import ViewContacts from "./ViewContacts";
import MUIDataTable from "mui-datatables";
import { DateTime } from "luxon";
import DeleteContactsListDialog from "./DeleteContactsListDialog";

function ManageContactLists() {
  const [contactLists, setContactLists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openCreateList, setOpenCreateList] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [showContacts, setShowContacts] = useState(false);
  const [contactListId, setContactListId] = useState(0);
  const [openDeleteContactList, setOpenDeleteContactList] = useState(false);
  const [selectedDeleteContactList, setSelectedDeleteContactList] = useState(
    {}
  );
  const { getAccessTokenSilently } = useAuth0();

  useEffect(async () => {
    await getContactLists();
    getClientLists();
  }, []);

  const getContactLists = async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    const result = await fetch(
      process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN + "contacts/list",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    const resultJson = await result.json();
    setContactLists(resultJson.results);
    setIsLoading(false);
  };

  const getClientLists = async () => {
    const token = await getAccessTokenSilently();
    const clientResult = await fetch(
      process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN + "clients/list",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    const clientResultJson = await clientResult.json();
    setClientList(clientResultJson.results);
  };

  const openCreateListDialog = () => {
    setOpenCreateList(true);
  };

  const handleCreateListDialogClose = () => {
    setOpenCreateList(false);
    setContactLists([]);
    getContactLists();
  };

  const handleViewContactsButton = (value, event) => {
    setContactListId(value);
    setShowContacts(true);
  };

  const handleViewContactsBackButton = () => {
    setShowContacts(false);
  };

  const handleOpenDeleteContactList = (value, event) => {
    setSelectedDeleteContactList(value);
    setOpenDeleteContactList(true);
  };

  const handleCloseDeleteContactListClose = () => {
    setOpenDeleteContactList(false);
    setContactLists([]);
    getContactLists();
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "create_date",
      label: "Create Date",
      options: {
        filter: false,
        sort: true,
        empty: true,
        sortDirection: "desc",
        customBodyRender: (value, tableMeta, updateValue) => {
          var date = DateTime.fromISO(value);
          return <span>{date.toLocaleString(DateTime.DATETIME_SHORT)}</span>;
        },
      },
    },
    {
      name: "id",
      label: "View Contacts",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              onClick={handleViewContactsButton.bind(this, value)}
            >
              Contacts
            </Button>
          );
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              onClick={handleOpenDeleteContactList.bind(this, value)}
            >
              Delete
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    print: false,
    download: false,
    selectableRows: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CircularProgress color="inherit" />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
  };

  if (showContacts) {
    return (
      <div>
        <ViewContacts
          backButtonFunction={handleViewContactsBackButton}
          contactListId={contactListId}
        ></ViewContacts>
      </div>
    );
  } else {
    return (
      <div>
        <Grid
          container
          spacing={2}
          sx={{ marginBottom: "20px" }}
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <Button
              sx={{ float: "right" }}
              onClick={openCreateListDialog}
              variant="contained"
            >
              Create List
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MUIDataTable
              title={"Contact Lists"}
              data={contactLists}
              columns={columns}
              options={options}
            />
          </Grid>
        </Grid>
        <CreateContactsDialog
          open={openCreateList}
          onClose={handleCreateListDialogClose}
          clientList={clientList}
        />
        <DeleteContactsListDialog
          open={openDeleteContactList}
          onClose={handleCloseDeleteContactListClose}
          deleteContactsList={selectedDeleteContactList}
        />
      </div>
    );
  }
}

export default ManageContactLists;
