import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CreateClientPhoneNumberDialog from "./CreateClientPhoneNumberDialog";
import { useAuth0 } from "@auth0/auth0-react";
import MUIDataTable from "mui-datatables";
import { DateTime } from "luxon";

function ManageClientPhoneNumbers(props) {
  const [phoneList, setPhoneList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCreatePhoneClient, setSelectedCreatePhoneClient] =
    useState("");
  const [openCreateClientPhoneDialog, setOpenCreateClientPhoneDialog] =
    useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { client_id, onBack } = props;
  const [disableButtons, setDisableButtons] = useState(false);

  useEffect(() => {
    getPhoneList();
  }, []);

  const getPhoneList = async () => {
    setIsLoading(true);
    setPhoneList([]);
    const token = await getAccessTokenSilently();
    fetch(
      process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN +
        "clients/phonenumbers/" +
        client_id,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setPhoneList(result.results);
          setIsLoading(false);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoading(false);
        }
      );
  };

  const deletePhoneNumber = async (value, event) => {
    setDisableButtons(true)
    const token = await getAccessTokenSilently();
    fetch(
      process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN +
        "clients/phonenumbers/delete",
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          phone_id: value,
        }),
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setDisableButtons(false)
          getPhoneList();
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoading(false);
        }
      );
  };

  const openCreateClientPhoneNumberDialog = () => {
    setSelectedCreatePhoneClient(client_id);
    setOpenCreateClientPhoneDialog(true);
  };

  const closeCreateClientPhoneNumberDialog = () => {
    setOpenCreateClientPhoneDialog(false);
    getPhoneList();
  };

  const columns = [
    {
      name: "phone_number",
      label: "Phone Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "create_date",
      label: "Create Date",
      options: {
        filter: false,
        sort: true,
        empty: true,
        sortDirection: "desc",
        customBodyRender: (value, tableMeta, updateValue) => {
          var date = DateTime.fromISO(value);
          return <span>{date.toLocaleString(DateTime.DATETIME_SHORT)}</span>;
        },
      },
    },
    {
      name: "phone_id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="contained"
              disabled={disableButtons}
              onClick={deletePhoneNumber.bind(this, value)}
            >
              Delete
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    print: false,
    download: false,
    selectableRows: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CircularProgress color="inherit" />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{ marginBottom: "20px" }}
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Grid item xs={2}>
          <Button
            sx={{ float: "left" }}
            variant="contained"
            onClick={onBack}
            startIcon={<ArrowBackIcon />}
            disabled={disableButtons}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={8}></Grid>
        <Grid item xs={2}>
          <Button
            sx={{ float: "right" }}
            onClick={openCreateClientPhoneNumberDialog}
            variant="contained"
            disabled={disableButtons}
          >
            Create Phone Number
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MUIDataTable
            title={"Phone Numbers"}
            data={phoneList}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
      <CreateClientPhoneNumberDialog
        open={openCreateClientPhoneDialog}
        onClose={closeCreateClientPhoneNumberDialog}
        client_id={selectedCreatePhoneClient}
      />
    </div>
  );
}

export default ManageClientPhoneNumbers;
