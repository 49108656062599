import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CreateClientDialog from "./CreateClientDialog";
import DeleteClientDialog from "./DeleteClientDialog";
import ManageClientPhoneNumbers from "./ClientPhoneNumbers";
import { useAuth0 } from "@auth0/auth0-react";
import MUIDataTable from "mui-datatables";
import { DateTime } from "luxon";

function ManageClients() {
  const [clientList, setClientList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openCreateClient, setOpenCreateClient] = useState(false);
  const [openDeleteClient, setOpenDeleteClient] = useState(false);
  const [selectedDeleteClient, setSelectedDeleteClient] = useState("");
  const [selectedPhoneClient, setSelectedPhoneClient] = useState("");
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getClientList();
  }, []);

  const getClientList = async () => {
    setIsLoading(true);
    setClientList([]);
    const token = await getAccessTokenSilently();
    const result = await fetch(
      process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN + "clients/list",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    const resultJson = await result.json();

    setClientList(resultJson.results);
    setIsLoading(false);
  };

  const openCreateClientDialog = () => {
    setOpenCreateClient(true);
  };

  const handleCreateClientDialogClose = () => {
    setOpenCreateClient(false);
    getClientList();
  };

  const handleOpenDeleteClient = (value, event) => {
    setSelectedDeleteClient(value);
    setOpenDeleteClient(true);
  };

  const handleCloseDeleteClientClose = () => {
    setOpenDeleteClient(false);
    getClientList();
  };

  const handleClientPhoneNumber = (value, event) => {
    setSelectedPhoneClient(value);
  };

  const handleBackButtonClickClientPhone = () => {
    setSelectedPhoneClient("");
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "create_date",
      label: "Create Date",
      options: {
        filter: false,
        sort: true,
        empty: true,
        sortDirection: "desc",
        customBodyRender: (value, tableMeta, updateValue) => {
          var date = DateTime.fromISO(value);
          return <span>{date.toLocaleString(DateTime.DATETIME_SHORT)}</span>;
        },
      },
    },
    {
      name: "id",
      label: "Phone Numbers",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="contained"
              onClick={handleClientPhoneNumber.bind(this, value)}
            >
              Add Phone Numbers
            </Button>
          );
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              onClick={handleOpenDeleteClient.bind(this, value)}
            >
              Delete
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    print: false,
    download: false,
    selectableRows: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CircularProgress color="inherit" />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
  };

  if (selectedPhoneClient) {
    return (
      <div>
        <ManageClientPhoneNumbers
          client_id={selectedPhoneClient}
          onBack={handleBackButtonClickClientPhone}
        ></ManageClientPhoneNumbers>
      </div>
    );
  } else {
    return (
      <div>
        <Grid
          container
          spacing={2}
          sx={{ marginBottom: "20px" }}
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <Button
              sx={{ float: "right" }}
              onClick={openCreateClientDialog}
              variant="contained"
            >
              Create Client
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MUIDataTable
              title={"Clients"}
              data={clientList}
              columns={columns}
              options={options}
            />
          </Grid>
        </Grid>
        <CreateClientDialog
          open={openCreateClient}
          onClose={handleCreateClientDialogClose}
        />
        <DeleteClientDialog
          open={openDeleteClient}
          onClose={handleCloseDeleteClientClose}
          deleteClient={selectedDeleteClient}
        />
      </div>
    );
  }
}

export default ManageClients;
