import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Button, DialogActions, DialogContent } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FileUpload from "react-material-file-upload";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import csvtemplate from "../../templates/ImportTemplate.csv";
import { useAuth0 } from "@auth0/auth0-react";

export default function CreateContactsDialog(props) {
  const { onClose, clientList, open } = props;
  const [files, setFiles] = useState([]);
  const [contactListName, setContactListName] = useState("");
  const [required, setIsRequired] = useState(false);
  const [clientError, setClientError] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [disableButtons, setDisableButtons] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const CHARACTER_LIMIT = 300;

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") {
      return;
    } else {
      onClose();
      setSelectedClient({});
      setContactListName("");
      setFiles([]);
      setIsRequired(false);
      setDisableButtons(false);
    }
  };

  const handleContactNameChange = (event) => {
    setContactListName(event.target.value);
  };

  const handleUploadButton = async () => {
    if (selectedClient == {}) {
      setClientError(true);
    } else if (contactListName.length === 0) {
      setIsRequired(true);
    } else {
      await getSignedUrl();
    }
  };

  async function getSignedUrl() {
    setDisableButtons(true);
    const token = await getAccessTokenSilently();
    try {
      const result = await fetch(
        process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN + "contacts/signedurl",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      const resultJson = await result.json();
      await uploadFile(resultJson);
    } catch (error) {
      console.error(error);
    }
  }

  async function uploadFile(signed_url) {
    const formData = new FormData();
    Object.keys(signed_url.results.fields).forEach((key) => {
      formData.append(key, signed_url.results.fields[key]);
    });
    formData.append("file", files[0]);
    const result = await fetch(signed_url.results.url, {
      method: "POST",
      body: formData,
    });
    //const textResult = await result.text();
    //const resultJson = await result.json();
    await contactUpload(signed_url.results.fields.key);
  }

  async function contactUpload(signedUrlKey) {
    const token = await getAccessTokenSilently();

    const body = {
      contactListName: contactListName,
      clientId: selectedClient.id,
      key: signedUrlKey,
    };

    try {
      const result = await fetch(
        process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN + "contacts/contactupload",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      handleClose();
    } catch (error) {
      console.error(error);
    }
  }

  const handleClientSelect = (event) => {
    setSelectedClient(event.target.value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Create Contact List</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ paddingTop: "20px" }}>
          <Grid item xs={12}>
            <FormControl fullWidth error={clientError}>
              <InputLabel id="demo-simple-select-label">Client</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedClient}
                label="Age"
                onChange={handleClientSelect}
              >
                {clientList.map((row) => (
                  <MenuItem key={row.id} value={row}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Error</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="outlined-basic"
              label="List Name"
              inputProps={{
                maxLength: CHARACTER_LIMIT,
              }}
              variant="outlined"
              value={contactListName}
              onChange={handleContactNameChange}
              helperText={`${contactListName.length}/${CHARACTER_LIMIT}`}
              error={required}
            />
          </Grid>
          <Grid item xs={12}>
            <FileUpload
              value={files}
              onChange={setFiles}
              disabled={disableButtons}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          download={"Broadcast-ImportTemlate.csv"}
          href={csvtemplate}
          disabled={disableButtons}
        >
          Download Template
        </Button>
        <div style={{ flex: "1 0 0" }} />
        <Button onClick={handleClose} disabled={disableButtons}>
          Cancel
        </Button>
        <Button
          onClick={handleUploadButton}
          disabled={disableButtons}
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateContactsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
