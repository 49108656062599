
const reportsApi =  {

    getDeliveryReportSignedUrl: async (token, campaign_id) => {
        try {
          const result = await fetch(
            process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN + "reports/deliveryurl",
            {
              method: "POST",
              headers: {
                Authorization: "Bearer " + token,
              },
              body: JSON.stringify({
                campaign_id: campaign_id,
              }),
            }
          );
    
          const resultJson = await result.json();
          
          window.location = resultJson.results;
        } catch (error) {
          console.error(error);
        }
      },
      getOnDemandCampaignReport: async (token, campaign_id) => {
        try {
          const result = await fetch(
            process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN + "reports/campaign",
            {
              method: "POST",
              headers: {
                Authorization: "Bearer " + token,
              },
              body: JSON.stringify({
                campaign_id: campaign_id,
              }),
            }
          );
    
          const resultJson = await result.json();
        } catch (error) {
          console.error(error);
        }
      },
      
  
}

export default reportsApi