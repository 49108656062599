import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import MUIDataTable from "mui-datatables";
import CircularProgress from "@mui/material/CircularProgress";

function ViewContacts(props) {
  const [contactsList, setContactsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const { backButtonFunction, contactListId } = props;

  useEffect(async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    const contactResult = await fetch(
      process.env.REACT_APP_AWS_API_GATEWAY_DOMAIN +
        "contacts/list/view/" +
        contactListId,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    const contactsResultJson = await contactResult.json();
    setContactsList(contactsResultJson.results);
    setIsLoading(false);
  }, []);

  const columns = [
    {
      name: "first_name",
      label: "First Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "phone",
      label: "Phone",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const options = {
    print: false,
    download: false,
    selectableRows: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CircularProgress color="inherit" />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{ marginBottom: "20px" }}
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Grid item xs={10}></Grid>
        <Grid item xs={2}>
          <Button
            sx={{ float: "right" }}
            variant="contained"
            onClick={backButtonFunction}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ height: 500 }}>
        <Grid item xs={12}>
          <MUIDataTable
            title={"Campaigns"}
            data={contactsList}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default ViewContacts;
